import styled from "styled-components";
import { SearchIcon as BaseSearchIcon, colors, XIcon } from "@commonsku/styles";
import React, { useRef } from "react";

const SearchContainer = styled.div<{ anchor?: "left" | "right" }>`
    display: flex;
    align-items: center;
    justify-content: ${({ anchor }) => (anchor === "right" ? "flex-end" : "flex-start")};
    gap: 4px;
`;

const SearchButton = styled.label`
    &&& {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: none;
        transition-duration: 0.5s;
        -moz-transition-duration: 0.5s;
        -webkit-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        background-color: white;
        color: black;
    }
`;

const SearchIcon = styled(BaseSearchIcon)`
    &&& {
        cursor: pointer;
    }
`;

const ExpandingInput = styled.input`
    &&& {
        outline: none;
        border: none;
        width: 65px;
        padding: 0 0 2px 16px;
        margin: 0;
        transition-duration: 0.5s;
        -moz-transition-duration: 0.5s;
        -webkit-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        &:focus {
            transition-duration: 0.5s;
            -moz-transition-duration: 0.5s;
            -webkit-transition-duration: 0.5s;
            -o-transition-duration: 0.5s;
            background-color: white;
            color: black;
            width: auto;
        }
        &:not(:focus) {
            box-shadow: unset;
        }
        &::placeholder {
            font-size: 14px;
            font-weight: 400;
            color: ${colors.primary1[65]};
            height: inherit;
            line-height: inherit;
        }
    }
`;

const ClearButton = styled.button`
    &&& {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: none;
        color: ${colors.primary1[65]};
    }
`;

export interface SearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
    query?: string;
    onChange: (e: React.SyntheticEvent) => void;
    onClear: () => void;
    anchor?: 'left' | 'right';
}

export const SearchBar = ({
    query,
    onChange,
    onClear,
    anchor = 'left',
    ...props
}: SearchProps) => {
    const contactSearchRef = useRef<HTMLInputElement>(null);

    return (
        <SearchContainer anchor={anchor}>
            <SearchButton htmlFor="searchright">
                <SearchIcon />
            </SearchButton>
            <ExpandingInput
                {...props}
                ref={contactSearchRef}
                id="searchright"
                type="search"
                name="search"
                value={query}
                placeholder="Search"
                onChange={onChange}
            />
            {query && (
                <ClearButton onClick={onClear}>
                  <XIcon />
                </ClearButton>
            )}
        </SearchContainer>
    );
};
